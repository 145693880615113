import http from '../HttpRequest.js'
class GlobalAPI {
	get( URL = "", params = {} ) {
        return http.get(`api/${URL}`, {params})
    }
	post(URL = "", params = {}) {
        var formData = new FormData();
		if (params) {
			for (var key in params) {
				formData.append(key, params[key])
			}
		}
        return http.post(`api/${URL}`, formData);
    }
}
export default new GlobalAPI()