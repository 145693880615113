import axios from 'axios'
const axiosInstance = new axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    params: {},
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
});
const requestHandler = (config) => {
    config.headers['app-front-request'] = true;
    config.headers['app-front-auth-id'] = (window.AuthUserObject ? window.AuthUserObject.id : null);
    return config
}
const responseHandler = (response) => {
    return response
}
const requestErrorHandler = (error) => {
    return Promise.reject({...error })
}
const responseErrorHandler = (error) => {
    return Promise.reject({...error })
}
axiosInstance.interceptors.request.use(config => requestHandler(config), error => requestErrorHandler(error));
axiosInstance.interceptors.response.use(response => responseHandler(response), error => responseErrorHandler(error));
export default axiosInstance