export default [{
    path: '/report',
    name: 'report',
    component: () =>
        import('@/views/report/Index.vue'),
    meta: {
        pageTitle: 'Report',
        breadcrumb: [{
            text: 'Reports',
            active: 'text-dark',
        }],
    },
}, {
    path: '/comparison-report',
    name: 'comparison-report',
    component: () =>
        import('@/views/report/comparison-report/Index.vue'),
    meta: {
        pageTitle: 'Comparison Report',
        breadcrumb: [{
            text: 'Reports',
            active: 'text-dark',
        }],
    },
}, {
    path: '/mobile-vs-desktop-report',
    name: 'mobile-vs-desktop-report',
    component: () =>
        import('@/views/report/mobile-vs-desktop-report/Index.vue'),
    meta: {
        pageTitle: 'Mobile Vs Desktop Conversions Report',
        breadcrumb: [{
            text: 'Reports',
            active: 'text-dark',
        }],
    },
}, {
    path: '/drip-report',
    name: 'drip-report',
    component: () =>
        import('@/views/report/drip-report/Index.vue'),
    meta: {
        pageTitle: 'Drip Push Emails',
        breadcrumb: [{
            text: 'Reports',
            active: 'text-dark',
        }],
    },
},]