import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'; /* import the fontawesome core */
/*
 * IMPORT FREE SOLID ICONS : START
 */
import { fas } from '@fortawesome/free-solid-svg-icons';
/*
 * IMPORT FREE SOLID ICONS : END
 */
/*
 * IMPORT FREE REGULAR ICONS : START
 */
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far); /* add icons to the library */
/*
 * IMPORT FREE REGULAR ICONS : END
 */
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);
/*
 * EX. <font-awesome-icon icon="fa-solid fa-credit-card" /> 
 * EX. <font-awesome-icon icon="fa-regular fa-credit-card" size="2x" />
 */