import http from '../HttpRequest.js'
class CaptureSettingAPI {
    get(URL = "", params = {}) {
        return http.get(`api/capture-setting/${URL}`, { params })
    }
    post(URL = "", params = {}) {
        var formData = new FormData();
        if (params) {
            for (var key in params) {
                if (params[key] != null) formData.append(key, params[key]);
            }
        }   
        return http.post(`api/capture-setting/${URL}`, formData)
    }
}
export default new CaptureSettingAPI()