import http from '../HttpRequest.js'
class ContactAPI {
    get(URL = "", params = {}) {
        return http.get(`api/contacts/${URL}`, { params })
    }
    post(URL = "", params = {}) {
        var formData = new FormData();
        if (params) {
            for (var key in params) {
                formData.append(key, params[key])
            }
        }
        return http.post(`api/contacts/${URL}`, formData);
    }
    delete(params) {
		var formData = new FormData();
        if (params) {
            for (var key in params) {
                formData.append(key, params[key])
            }
        }
        return http.post(`api/contacts/delete-contact-list`, formData);
    }
    add(params = {}) {
		var formData = new FormData();
        if (params) {
            for (var key in params) {
                formData.append(key, params[key])
            }
        }
        return http.post('api/contacts/save-contact-list', formData)
    }
    update(params = {}) {
        var formData = new FormData();
        if (params) {
            for (var key in params) {
                formData.append(key, params[key])
            }
        }
        return http.post(`api/site/update/${URL}`, formData)
    }
}
export default new ContactAPI()