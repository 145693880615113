<template>
	<div id="app" class="h-100" :class="[skinClasses]">
		<component :is="layout">
			<router-view />
		</component>
	</div>
</template>

<script>
	import {
		$themeColors,
		$themeBreakpoints,
		$themeConfig
	} from '@themeConfig'
	import {
		provideToast
	} from 'vue-toastification/composition'
	import {
		watch
	} from '@vue/composition-api'
	import useAppConfig from '@core/app-config/useAppConfig'
	import {
		useWindowSize,
		useCssVar
	} from '@vueuse/core'

	import store from '@/store'

	export default {
		components: {
			LayoutVertical: () => import('@/layouts/vertical/LayoutVertical.vue'),
			LayoutFull: () => import('@/layouts/full/LayoutFull.vue'),
		},
		computed: {
			layout() {
				if (this.$route.meta.layout === 'full') return 'layout-full'
				return `layout-${this.contentLayoutType}`
			},
			contentLayoutType() {
				return this.$store.state.appConfig.layout.type
			},
		},
		beforeCreate() {
			const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'] /* Set colors in theme */

			for (let i = 0, len = colors.length; i < len; i++) {
				$themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim() /* eslint-disable-next-line no-plusplus */
			}

			const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'] /* Set Theme Breakpoints */

			for (let i = 0, len = breakpoints.length; i < len; i++) {
				$themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2)) /* eslint-disable-next-line no-plusplus */
			}

			const {
				isRTL
			} = $themeConfig.layout
			document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr') /* Set RTL */
		},
		setup() {
			const {
				skin,
				skinClasses
			} = useAppConfig()

			if (skin.value === 'dark') document.body.classList.add('dark-layout') /* If skin is dark when initialized => Add class to body */

			/*
			 * Provide toast for Composition API usage
			 * This for those apps/components which uses composition API
			 * Demos will still use Options API for ease
			 */
			provideToast({
				hideProgressBar: true,
				closeOnClick: false,
				closeButton: false,
				icon: false,
				timeout: 3000,
				transition: 'Vue-Toastification__fade',
			})

			store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth) /* Set Window Width in store */
			const {
				width: windowWidth
			} = useWindowSize()

			watch(windowWidth, val => {
				store.commit('app/UPDATE_WINDOW_WIDTH', val)
			})

			return {
				skinClasses,
			}
		},
	}
</script>