export default {
	namespaced: true,
	state: {
		modules: {},
	},
	getters: {
		getFilter: (state) => (params) => {
			if (_.has(state.modules[params.page], 'element_value')) {
				if (_.has(params, 'filterKey')) {
					if (_.has(state.modules[params.page]['element_value'], params.filterKey)) {
						return state.modules[params.page]['element_value'][params.filterKey];
					} else {
						return '';
					}
				} else {
					return state.modules[params.page]['element_value'];
				}
			} else {
				return {};
			}
		}
	},
	mutations: {
		/**
		 * FOR FETCH DEFAULT MODULES ELEMENT SETTINGS
		 * @params Array
		 */
		INSERT(state, pageFilterdata) {

			/* FOR DEFAULT ALL MODULES FILTERS : START */
			let pageFiltersArray = _.keyBy(_.map(pageFilterdata, (page) => {
				page.element_value = JSON.parse(page.element_value); /* CONVERT STRING TO JSON FORMAT */
				return page;
			}), 'page_name');
			/* FOR DEFAULT ALL MODULES FILTERS : END */

			state.modules = pageFiltersArray;
		},

		/**
		 * FOR UPDATE DEFAULT MODULES ELEMENT SETTINGS
		 * @params Object
		 */
		UPDATE(state, params) {
			if (_.has(state.modules, params.page)) {

				/* DEFAULT KEY 'ELEMENT_VALUE' ADD IF NOT EXIST : START */
				if (!_.has(state.modules[params.page], 'element_value')) {
					state.modules[params.page]['element_value'] = {};
				}
				/* DEFAULT KEY 'ELEMENT_VALUE' ADD IF NOT EXIST : END */

				/* DEFAULT FILTER KEY ADD IF NOT EXIST : START */
				if (!_.has(state.modules[params.page]['element_value'], params.filterKey)) {
					state.modules[params.page]['element_value'][params.filterKey] = '';
				}
				/* DEFAULT FILTER KEY ADD IF NOT EXIST : END */

				if (_.has(state.modules[params.page]['element_value'], params.filterKey)) {
					state.modules[params.page]['element_value'][params.filterKey] = params.filterValue;
				}
			} else {
				/* FOR MAKE NEW PAGE WITH FILTERS : START */
				state.modules[params.page] = {};
				state.modules[params.page]['element_value'] = {};
				state.modules[params.page]['page_name'] = params.page;
				state.modules[params.page]['element_value'][params.filterKey] = params.filterValue;
				/* FOR MAKE NEW PAGE WITH FILTERS : END */
			}
		},
	},
	actions: {},
}