import http from '../HttpRequest.js'
class SiteAPI {
    get(URL = "", params = {}) {
        return http.get(`api/site/${URL}`, { params })
    }
    setSession(params = {}) {
        var formData = new FormData();
        if (params) {
            for (var key in params) {
                formData.append(key, params[key])
            }
        }
        return http.post(`api/site/set/session`, formData);
    }
    delete(id) {
        return http.delete('api/site/deleteSite/' + id)
    }
    add(params = {}) {
        var formData = new FormData();
        if (params) {
            for (var key in params) {
                formData.append(key, params[key])
            }
        }
        return http.post('api/site/add', formData);
    }
    update(URL = "", params = {}) {
        var formData = new FormData();
        if (params) {
            for (var key in params) {
                formData.append(key, params[key])
            }
        }
        return http.post(`api/site/update/${URL}`, formData)
    }
}
export default new SiteAPI()