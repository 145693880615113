export default [{
    path: '/profile',
    name: 'profile',
    component: () =>
        import ('@/views/account-setting/AccountSetting.vue'),
    meta: {
        pageTitle: 'Profile Settings',
        breadcrumb: [{
            text: 'Pages',
        }],
    },
}, {
    path: '/site-settings',
    name: 'site-settings',
    component: () =>
        import ('@/views/site-setting/siteSettings.vue'),
    meta: {
        pageTitle: 'Site Settings',
    },
}, {
    path: '/site-users',
    name: 'site-users',
    component: () =>
        import ('@/views/site-user/SiteUser.vue'),
    meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
    },
}, ]