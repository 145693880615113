import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import router from './router'
import store from './store'
import App from './App.vue'
import lodash from 'lodash'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import './global-components' /* Global Components */
// import Select2Component
import Select2 from 'v-select2-component';
/* 3RD PARTY PLUGINS : START */
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-moment'
import '@/libs/font-awesome-icon'
import '@/libs/mobile-detection/index'
/* 3RD PARTY PLUGINS : END */
window.EventBus = new Vue();
import '@/services/Index' /* API SERVICES */
/* BSV PLUGIN REGISTRATION : START */
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
/* BSV PLUGIN REGISTRATION : END */
Vue.use(VueCompositionAPI) /* Composition API */
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker); /* DateRangePicker */
Vue.component('Select2', Select2);
require('@core/scss/core.scss') /* import core styles */
require('@/assets/scss/style.scss') /* import assets styles */
Vue.prototype.$_ = lodash
Vue.config.productionTip = false;
Vue.config.devtools = true;

(() => {

	Vue.prototype.$globalAPI.get('get-auth-user-session-data').then((response) => {

		response = response.data;

		window.AuthUserObject = response.frontendAuthUser
		window.AuthSiteObject = response.frontendAuthSite
		window.AuthDefaultElementFilters = response.frontendAuthDefaultElementFilters

		new Vue({
			router,
			store,
			render: h => h(App),
			data() {
				return {
					appBaseURL: process.env.VUE_APP_BASE_URL,
					authUserObject: window.AuthUserObject,
					appSiteObject: window.AuthSiteObject,
					authDefaultElementFilters: window.AuthDefaultElementFilters,
				}
			},
			mounted: function () { },
			created() {
				if (!_.isEmpty(this.AuthUserObject)) {
					let authUser = {
						'id': this.AuthUserObject.id,
						'firstName': this.AuthUserObject.firstName,
						'lastName': this.AuthUserObject.lastName,
						'email': this.AuthUserObject.email,
						'role': this.AuthUserObject.role,
						'photo': this.AuthUserObject.photo,
						'photo_thumb': this.AuthUserObject.photo_thumb
					};
					this.$store.commit('appAuth/APP_LOGIN', {
						'userData': authUser,
						'isLogin': true
					});
				}
				if (!_.isEmpty(this.authDefaultElementFilters)) {
					this.$store.commit('appModuleFilters/INSERT', this.authDefaultElementFilters);
				}
			},
			methods: {
				getUploadFilePath(path) {
					return `/uploads/${path}`;
				},

				/**
				 * STORE FILTER IN VUEX AND DB
				 */
				setDefaultFilterElements(params) {

					/* STORE FILTER IN VUEX : START */
					for (const [filterKey, filterValue] of Object.entries(params.filterObject)) {
						this.$store.commit('appModuleFilters/UPDATE', {
							page: params.page,
							filterKey: filterKey,
							filterValue: filterValue
						});
					}
					/* STORE FILTER IN VUEX : END */

					/* STORE FILTER IN DB : START */
					this.$globalAPI.post('set-default-filter-elements', {
						'page_name': params.page,
						'filter_array': JSON.stringify(params.filterObject),
					});
					/* STORE FILTER IN DB : END */
				},
			}
		}).$mount('#app');
	});
})();
