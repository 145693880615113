import http from '../HttpRequest.js'
class ManageCaptureAPI {
    get(URL = "", params = {}) {
        return http.get(`api/manage-capture/${URL}`, { params })
    }
    post(URL = "", params = {}) {
        var formData = new FormData();
        if (params) {
            for (var key in params) {
                formData.append(key, params[key])
            }
        }
        return http.post(`api/manage-capture/${URL}`, formData)
    }
}
export default new ManageCaptureAPI()