export default [{
    path: '/contacts',
    name: 'contacts',
    component: () =>
        import('@/views/contact/main-contact/ContactList.vue'),
    meta: {
        pageTitle: 'Contact List',
        breadcrumb: [{
            text: 'Contacts',
            active: 'text-dark'
        }],
    },
}, {
    path: '/contacts/:contact_list_id/contacts-list-subscribers',
    name: 'contacts-list-subscribers',
    component: () =>
        import('@/views/contact/main-contact/ContactListSubscribers.vue'),
    meta: {
        navActiveLink: 'contacts',
        pageTitle: 'Contact List Subcribers',
        
        beforeEnter(to, _, next) {
            if (isNaN(to.params.contact_list_id)) {
                next({ name: 'contacts' })
            } else {
                next()
            }
        }
    },
}, {
    path: '/contacts-fortunewheel',
    name: 'contacts-fortunewheel',
    component: () =>
        import('@/views/contact/fortune-contact/ContactList.vue'),
    meta: {
        pageTitle: 'Fortune Contacts',
        breadcrumb: [{
            text: 'Contacts',
            active: 'text-dark',
        }],
    },
}, {
    path: '/contacts-fortunewheel/:contact_list_id/fortunewheel-subscribers',
    name: 'fortunewheel-subscribers',
    component: () =>
        import('@/views/contact/fortune-contact/ContactListSubscribers.vue'),
    meta: {
        navActiveLink: 'contacts-fortunewheel',
        pageTitle: 'Fortune Wheel Subcribers',
        beforeEnter(to, _, next) {
            if (isNaN(to.params.contact_list_id)) {
                next({ name: 'contacts' })
            } else {
                next()
            }
        }
    },
},]