// UI RELATED CHANGES
export default [{
    path: '/capture/:setting_id/setting',
    name: 'capture-setting',
    navActiveLink: 'manage-site',
    component: () =>
        import ('@/views/capture-setting/CaptureSettings.vue'),
    meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        hideNavbar: true,
    },
},
{
    path: '/capture/:setting_id/setting/new',
    name: 'capture-setting-new',
    navActiveLink: 'manage-site',
    component: () =>
        import ('@/views/capture-setting/CaptureSettings.vue'),
    meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        hideNavbar: true,
    },
}, {
    path: '/capture/:setting_id/setting/clone',
    name: 'capture-setting-clone',
    navActiveLink: 'manage-site',
    component: () =>
        import ('@/views/capture-setting/CaptureSettings.vue'),
    meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        hideNavbar: true,
    },
}]