import Vue from 'vue'
import SiteAPI from './SiteAPI';
import SummaryAPI from './SummaryAPI';
import AuthAPI from './AuthAPI';
import GlobalAPI from './GlobalAPI';
import ContactAPI from './ContactAPI';
import ReportAPI from './ReportAPI';
import TakeScreenshotAPI from './TakeScreenshotAPI';
import ManageCaptureAPI from './ManageCaptureAPI';
import CaptureSettingAPI from './CaptureSettingAPI';

Vue.prototype.$siteAPI = SiteAPI;
Vue.prototype.$summaryAPI = SummaryAPI;
Vue.prototype.$authAPI = AuthAPI;
Vue.prototype.$globalAPI = GlobalAPI;
Vue.prototype.$contactAPI = ContactAPI;
Vue.prototype.$reportAPI = ReportAPI;
Vue.prototype.$takeScreenshotAPI = TakeScreenshotAPI;
Vue.prototype.$manageCaptureAPI = ManageCaptureAPI;
Vue.prototype.$captureSettingAPI = CaptureSettingAPI;