export default [{
    path: '/login',
    name: 'login',
    component: () =>
        import ('@/views/authentication/Login.vue'),
    meta: {
        layout: 'full',
    },
}, {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () =>
        import ('@/views/authentication/ForgotPassword.vue'),
    meta: {
        layout: 'full',
    },
}, {
    path: '/reset-password',
    name: 'reset-password',
    component: () =>
        import ('@/views/authentication/ResetPassword.vue'),
    meta: {
        layout: 'full',
    },
}, {
    path: '/new-user-set-password',
    name: 'new-user-set-password',
    component: () =>
        import ('@/views/authentication/NewUserSetPassword.vue'),
    meta: {
        layout: 'full',
    },
}, ]