export default {
    namespaced: true,
    state: {
        AuthUser: null,
        IsLogin: false,
    },
    getters: {
		AUTH_USER(state) {
			return state.AuthUser;
		},
		IS_LOGIN(state) {
			return state.IsLogin;
		}
	},
    mutations: {
        APP_LOGIN(state, data) {
            state.AuthUser = data.userData
			state.IsLogin = data.isLogin
        },
        APP_LOGOUT(state) {
            state.AuthUser = null
			state.IsLogin = false
        },
    },
    actions: {
		setAuthData(context, credentials) {
			console.log(credentials);
			context.commit('APP_LOGIN', credentials);
			return true;
		}
	}
}