import http from '../HttpRequest.js'
class ReportAPI {
    get( URL = "", params = {} ) {
        return http.get(`api/reports/${URL}`, {params})
    }
	post(URL = "", params = {}) {
        var formData = new FormData();
        if (params) {
            for (var key in params) {
                formData.append(key, params[key])
            }
        }
        return http.post(`api/reports/${URL}`, formData)
    }
}
export default new ReportAPI()