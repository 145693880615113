import http from '../HttpRequest.js'
class AuthAPI {
    login(params = {}) {
        var formData = new FormData();
		if (params) {
			for (var key in params) {
				formData.append(key, params[key])
			}
		}
        return http.post(`api/login`, formData);
    }
    logout(params = {}) {
		var formData = new FormData();
        if (params) {
			for (var key in params) {
				formData.append(key, params[key])
			}
		}
        return http.post('api/logout', formData)
    }
	profile(URL = "", params = {}) {
		var formData = new FormData();
		if (params) {
			for (var key in params) {
				formData.append(key, params[key])
			}
		}
		return http.post(`api/profile/${URL}`, formData)
	}
}
export default new AuthAPI()